<template>
  <div class="security-code mt-auto mb-auto">
    <h3>{{ title }}</h3>
    <p>We just send you a verify code. Check your inbox to get them.</p>
    <form class="mt-8"
          @submit.prevent="submitSecurityCode">
      <div class="codes" ref="inputs">
        <div
          v-for="(code, idx) in 4"
          :key="idx"
          class="input-field"
        >
          <input
            class="focus:outline-none"
            type="text"
            maxlength="1"
            autocorrect="off"
            autocomplete="off"
            @paste="pasteCode"
            autocapitalize="off"
            v-model="codeArray[idx]"
            @keyup="handleType($event, idx)"
          >
        </div>
      </div>
      <div class="forgot flex mb-4 mt-4">
        <div class="ml-auto">
          Already have an account?
          <span class="cursor-pointer text-white ml-1"
                @click="redirectPage">Log In</span>
        </div>
      </div>
      <p v-if="errMsg" class="err-msg">{{ errMsg }}</p>
      <button type="submit" :title="buttonName" class="btn btn-yellow">{{ buttonName }}</button>
    </form>
  </div>
</template>

<script>
const ignoreArrKeys = ['Backspace', 'Delete', 'Tab']

export default {
  name: 'SecurityCode',
  props: ['title', 'buttonName'],
  data: () => ({
    codeArray: [],
    disabled: false
  }),
  computed: {
    errMsg () {
      return this.$store.state.auth.errMsg
    }
  },
  mounted () {
    this.$refs.inputs.children[0].querySelector('input').focus()
  },
  methods: {
    pasteCode (ev) {
      this.codeArray = ev.clipboardData.getData('text').split('')
      ev.target.parentElement.parentElement.children[3].querySelector('input').focus()
    },
    redirectPage () {
      window.sessionStorage.removeItem('token')
      this.$store.state.auth.securityPage = ''
      if (this.$route.path !== '/login') {
        this.$router.push('/login')
      }
    },
    submitSecurityCode () {
      if (this.errMsg) this.disabled = false
      if (!this.disabled) {
        this.disabled = true
        this.$emit('submitCode', this.codeArray.join(''))
      }
    },
    focusInputIdx (ev, nextId) {
      const _ev = ev.target.parentElement.parentElement.children[nextId].querySelector('input')
      _ev.focus()
      _ev.setSelectionRange(this.codeArray.length, this.codeArray.length)
    },
    handleType (ev, idx) {
      if (ev.key.includes('Arrow')) {
        if (ev.key === 'ArrowRight' && idx < 3) this.focusInputIdx(ev, idx + 1)
        else if (ev.key === 'ArrowLeft' && idx > 0) this.focusInputIdx(ev, idx - 1)
        return
      }
      if (/^\d+$/.test(this.codeArray[idx]) && !ignoreArrKeys.includes(ev.key) && idx !== 3) {
        this.focusInputIdx(ev, idx + 1)
      }
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/styles/vars";
  .security-code {
    max-width: 303px;
    p {
      max-width: 190px;
      font-family: $roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin-top: 24px;
      color: $white;
    }
    .codes {
      display: flex;
      justify-content: space-between;
      .input-field {
        padding: 12px 10px;
        width: 63px;
        input[type=text] {
          font-family: $roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          letter-spacing: -0.03em;
          color: $white;
        }
      }
    }
  }
</style>
