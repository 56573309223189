<template>
  <component
    :is="signUpComponent"
    title="Sign up"
    buttonName="Sign up"
    @submit="submitSignUp"
    @submitCode="handleSubmitCode"
  />
</template>

<script>
import SignUpProfile from '@/components/Registration/SignUpProfile'
import SecurityCode from '@/components/Registration/SecurityCode'

export default {
  name: 'SignUp',
  data: () => ({
    signUpPage: 'SignUpProfile'
  }),
  components: {
    SignUpProfile,
    SecurityCode
  },
  computed: {
    signUpComponent () {
      const currenPage = this.$store.state.auth.securityPage
      return currenPage === 'SecurityCode' ? 'SecurityCode' : 'SignUpProfile'
    }
  },
  methods: {
    submitSignUp ({ name, password }) {
      this.$store.dispatch('signup', {
        email: name,
        password
      })
    },
    handleSubmitCode (code) {
      this.$store.dispatch('verify_code', code)
    }
  }
}
</script>
